<template>
  <div class='upload-pan-main-wrapper'>
    <a-form>
      <a-row style='display: unset'>
        <a-col>
          <div style='margin-top: 100px'>
            <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
            <div>
              <div class='upload-pan-title'>Upload PAN</div>
              <div class='upload-pan-stepper'>Hello {{ clientName }}!</div>

              <div class='upload-pan-sub-title-description'>
                PAN is compulsory for a demat account. We will read data
              </div>
              <div class='upload-pan-sub-title-description'>
                automatically from the uploaded PAN card. You can also edit details
                after uploading it.
              </div>
              <div v-if='onPanUpload' class='upload-pan-upload-btn'>
                <a-upload
                  accept='image/png, image/jpeg, image/jpg'
                  :max-Count='1'
                  :showUploadList='false'
                  :customRequest='onUploadPan'
                >
                  <a-button :disabled='disable'
                    style='width: 100%; height: 44px; border: 1px solid #79a89b;'
                  >
                  <span style="float: left;">
                    Upload File
                  </span>
                    <cloud-upload-outlined style="font-size: 20px;float: right;" />
                  </a-button>
                </a-upload>
              </div>
              <div v-if='panUploaded'>
                <div
                  class='uploaded-file-name-with-delete-btn'
                  style='display: flex'
                >
                  <div>{{ uploadedFileName }}</div>
                    <div @click='confirm' style='cursor: pointer'>
                      <delete-outlined />
                    </div>
                </div>
                <div class='prefilled-pan-details-fields-wrapper'>
                  <div class='input-field-title'>Name as per PAN</div>
                  <a-form-item v-bind='validateInfos.name'>
                    <a-input
                      class='input-field'
                      v-model:value='modelRef.name'
                      placeholder='Preffiled Applicants name'
                      :disabled='true'
                    >
                    </a-input>
                  </a-form-item>
                </div>
                <div class='prefilled-pan-details-fields-wrapper'>
                  <div class='input-field-title'>Father's Name as per PAN</div>
                  <a-form-item v-bind='validateInfos.fatherName'>
                    <a-input
                      class='input-field'
                      v-model:value='modelRef.fatherName'
                      placeholder='Preffiled Fathers name'
                      :disabled='true'
                    >
                    </a-input>
                  </a-form-item>
                </div>
                <div class='pan-details-correct-btn-wrapper'>
                  <a-button type='primary' @click='onClickContinue' :loading="load"
                    >My PAN details are correct</a-button
                  >
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import {
  onMounted,
  ref,
  reactive,
} from 'vue';
import {
  DeleteOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons-vue';
import { Form } from 'ant-design-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

const { useForm } = Form;

export default {
  components: {
    DeleteOutlined,
    CloudUploadOutlined,
  },
  setup() {
    const store = useStore();
    const disable = ref(false);
    const load = ref(false);
    const clientName = ref('');
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          clientName.value = response.data.data.prefill.name;
        })
        .catch(() => {
        });
    });
    // const router = useRouter();
    const onPanUpload = ref(true);
    const panUploaded = ref(false);
    // const clientName = computed(
    //   () => store.getters['onboardingStore/getClientName'],
    // ).value;
    // const handleChange = ({ file, fileList }) => {
    //   if (file.status !== 'uploading') {
    //     console.log(file, fileList);
    //   }
    // };

    // const beforeUpload = file => {
    //   const isPNG = file.type === 'image/png';

    //   if (!isPNG) {
    //     message.error(`${file.name} is not a png file`);
    //   }

    //   return isPNG || Upload.LIST_IGNORE;
    // };
    const modelRef = reactive({
      name: '',
      fatherName: '',
    });

    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Please enter name',
        },
      ],
      fatherName: [
        {
          required: true,
          message: 'Please enter father name',
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );
    const uploadedFileName = ref('');
    const waitMessage = null;
    const waitDescription = 'Please wait while we verify your PAN details';
    const s3Link = ref('');
    const onUploadPan = ({ onSuccess, file }) => {
      const data = new FormData();
      data.append('file', file);
      data.append('documentType', 'PAN');
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      disable.value = true;
      services
        .uploadDocument(data, config)
        .then((uploadResponse) => {
          uploadedFileName.value = uploadResponse.data.name;
          Operations.showNotification(waitMessage, waitDescription);
          s3Link.value = uploadResponse.data.url;
          const UploadedData = {
            image: s3Link.value,
          };
          services
            .panUpload(UploadedData)
            .then((response) => {
              Operations.showNotification(waitMessage, waitDescription);
              onSuccess(null, response);
              disable.value = false;
              const responseData = response;
              modelRef.name = responseData.data.name;
              modelRef.fatherName = responseData.data.fatherName;
              onPanUpload.value = false;
              panUploaded.value = true;
            })
            .catch((error) => {
              Operations.showNotification(waitMessage, waitDescription);
              const systemError = Operations.axiosErrorHandler(error);
              const message = systemError.status;
              const description = systemError.message
                ? systemError.message
                : '';
              Operations.notificationError(message, description);
              disable.value = false;
              onPanUpload.value = true;
              panUploaded.value = false;
            });
        })
        .catch(() => {
        });
    };

    const confirm = () => {
      panUploaded.value = false;
      onPanUpload.value = true;
    };

    const onClickContinue = () => {
      validate().then(() => {
        load.value = true;
        disable.value = true;
        services
          .confirmPanDetails(modelRef)
          .then((response) => {
            load.value = false;
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          })
          .catch(() => {
            load.value = false;
            disable.value = false;
          });
      });
    };
    return {
      onClickContinue,
      onPanUpload,
      panUploaded,
      // handleChange,
      // beforeUpload,
      onUploadPan,
      clientName,
      validateInfos,
      resetFields,
      modelRef,
      confirm,
      uploadedFileName,
      s3Link,
      disable,
      load,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/uploadPan.scss';
</style>
